<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="true" title="Bank Reconciliation List"
                   @onClickAddNewButton="onClickAddNewButton"/>
    </div>

    <ListTable :reconciliation-list="reconciliationList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, onMounted} from 'vue';
import TitleButton from '@/components/atom/TitleButton'
import ListTable from '@/components/molecule/company/bank-reconciliation/BankReconciliationListingTable.vue'
import Pagination from '@/components/atom/Pagination'
import {useRoute, useRouter} from "vue-router";
import handleReport from "@/services/modules/accountingReport";

const {fetchReconciliationList} = handleReport();
const router = useRouter()
const route = useRoute()
const offset = ref(20);

let page = ref(1);
let loading = ref(false);
let reconciliationList = ref({});
let companyId = computed(() => route.params.companyId);

function onClickAddNewButton() {
  router.push({name: 'bank-reconciliation-create', params: route.params, query: route.query})
}

async function getReconciliations() {
  try {
    const res = await fetchReconciliationList(getQuery());
    if (res.status) {
      reconciliationList.value = res.data;
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  if (page.value) query += '&page=' + page.value
  return query
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getReconciliations();
  }, 100)
}

onMounted(async () => {
  await getReconciliations();
})
</script>

<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Reconciliation Date</th>
          <th>Reconciliation Ref</th>
          <th>Cash & Bank Account Name</th>
          <th>Opening Date</th>
          <th>Closing Date</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in reconciliationList.data" :key="i">
          <td>{{ item.reconciliation_date }}</td>
          <td>{{ item.reconciliation_ref }}</td>
          <td>{{ item.head.name }}</td>
          <td>{{ item.opening_date }}</td>
          <td>{{ item.closing_date }}</td>
          <td>
            <button class="btn btn-primary btn-sm"
                    @click="goToEdit(item.reconciliation_ref)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-eye"></i>
            </button>
            <button class="btn btn-secondary btn-sm"
                    @click="goToPrint(item.reconciliation_ref)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!reconciliationList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();
const props  = defineProps({
  reconciliationList: Object,
});

const goToEdit = (reconciliation_ref) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId
  }

  let query = route.query;
  query.reconciliationRef = reconciliation_ref;

  router.push({
    name: 'bank-reconciliation-create',
    params: param,
    query: query
  })
}

const goToPrint = (reconciliation_ref) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId
  }

  let query = route.query;
  query.reconciliationRef = reconciliation_ref;

  router.push({
    name: 'bank-reconciliation-print',
    params: param,
    query: query
  })
}

</script>